import {login as apiLogin, logout as apiLogout} from '@/auth/api';
import {getCurrentUser} from '@/users/api/users';
import {LoadingFlag} from '@/loading/types/LoadingFlags';
import {getInstance} from '@/auth/plugins/authServicePlugin';

export function useAuthService() {
  return getInstance();
}

export class AuthService {
  static excludedRoutes = ['auth/login', 'auth/refresh', 'auth/logout'];

  constructor(httpService, userStorage, promiseManager, router, store, hierarchyService) {
    this.HTTP = httpService;
    this.userStorage = userStorage;
    this.promiseManager = promiseManager;
    this.router = router;
    this.store = store;
    this.hierarchyService = hierarchyService;

    this.interactiveLoginHandler = null;
    this.nonInteractiveLoginHandler = null;
    this.userStorageHandler = null;
    this.logoutHandler = null;
    this.responseHandler = null;
    this.loginRedirectHandler = null;
    this.logoutRedirectHandler = null;
    this.interactiveRefreshHandler = null;
    this.resendHandler = null;
    this.refreshFailHandler = null;

    this._postUserLoadCallbacks = [];
    this._postLogoutCallbacks = [];

    this.currentUserPromise = null;
    this.redirect = '/dashboard';

    this._isUserAnonymous = false;

    this.setupHandlers();
  }

  setupHandlers() {
    this.setupRefreshFailHandler();
    this.setupResendHandler();
    this.setupLoginRedirectHandler();
    this.setupLogoutRedirectHandler();
    this.setupUserStorageHandler();
    this.setupInteractiveLoginHandler();
    this.setupNonInteractiveLoginHandler();
    this.setupLogoutHandler();
    this.setupResponseHandler();
  }

  /**
   * Register a callback to run post User load
   * @param callback
   */
  postUserLoad(callback) {
    this._postUserLoadCallbacks.push(callback);
  }

  /**
   * Register a callback to run post logout
   * @param callback
   */
  postLogout(callback) {
    this._postLogoutCallbacks.push(callback);
  }

  /**
   * Fetches the current user and stores it
   * @param force
   * @param args
   * @returns {*}
   */
  async fetchCurrentUser(force = false, ...args) {
    if (!force) {
      if (this.isUserLoaded && this.user) {
        return this.user;
      } else if (this.currentUserPromise) {
        return this.currentUserPromise;
      }
    }
    this.currentUserPromise = this.promiseManager.loadingHandler(
      LoadingFlag.CurrentUser,
      async () =>
        getCurrentUser(...args)
          .then(
            async (response) => {
              this._isUserAnonymous = false;
              return response;
            },
            (err) => {
              if (err && err.response && err.response.status === 401) {
                this._isUserAnonymous = true;
              }
              throw err;
            }
          )
          .then(async (response) => {
            await this.hierarchyService.fetchForCurrentUser();
            return this.userStorageHandler(response);
          })
          .then((user) => {
            for (const callback of this._postUserLoadCallbacks) {
              callback(user);
            }
            return user;
          })
          .then(async () => {
            this.currentUserPromise = null;
            return this.user;
          })
    );
    return this.currentUserPromise;
  }

  get user() {
    return this.userStorage.get();
  }

  get isUserLoading() {
    return this.promiseManager.isLoading(LoadingFlag.CurrentUser);
  }

  get isUserLoaded() {
    return this.promiseManager.isLoaded(LoadingFlag.CurrentUser);
  }

  get isUserError() {
    return this.promiseManager.isError(LoadingFlag.CurrentUser);
  }

  get isUserResolved() {
    return this.promiseManager.isResolved(LoadingFlag.CurrentUser);
  }

  get isUserAnonymous() {
    return !this.isUserLoaded && this._isUserAnonymous;
  }

  get hasUserEverLoggedIn() {
    return this.user !== null;
  }

  /**
   * Login and set the token on this instance
   * @returns {Promise<void | Promise<any | never>>}
   */
  async interactiveLogin(...args) {
    return this.promiseManager
      .loadingHandler(LoadingFlag.LoggingIn, () => apiLogin(...args))
      .then(this.interactiveLoginHandler);
  }

  /**
   * Login and set the token on this instance, but do not redirect
   * @returns {Promise<void | Promise<any | never>>}
   */
  async nonInteractiveLogin(...args) {
    return this.promiseManager
      .loadingHandler(LoadingFlag.LoggingIn, () => apiLogin(...args))
      .then(this.nonInteractiveLoginHandler);
  }

  /**
   * Logout and on success, clear the authentication token
   * @returns {Promise<void | Promise<any | never>>}
   */
  async logout() {
    return apiLogout().finally(this.logoutHandler);
  }

  /**
   * Set the default interactive login handler
   */
  setupInteractiveLoginHandler() {
    this.interactiveLoginHandler = async (response) => {
      return this.nonInteractiveLoginHandler(response).then(this.loginRedirectHandler);
    };
  }

  /**
   * Set the default non-interactive login handler
   */
  setupNonInteractiveLoginHandler() {
    this.nonInteractiveLoginHandler = async () => {
      return this.fetchCurrentUser(true);
    };
  }

  /**
   * Set the default logout handler
   */
  setupLogoutHandler() {
    this.logoutHandler = async () => {
      this._isUserAnonymous = true;
      this.promiseManager.clearAllFlags();
      this.currentUserPromise = this.promiseManager.loadingHandler(LoadingFlag.CurrentUser, () =>
        Promise.reject({isLogout: true})
      );
      for (const callback of this._postLogoutCallbacks) {
        callback();
      }
      this.logoutRedirectHandler();
    };
  }

  /**
   * Set the default error response handler
   */
  setupResponseHandler() {
    this.responseHandler = async (error) => {
      if (error && error.response) {
        const statusCode = error.response.status;
        const config = error.config;
        if (statusCode === 401 && !this.isExcludedRoute(config.url)) {
          return this.refreshFailHandler(error);
        }
      }
      return Promise.reject(error);
    };

    this.HTTP.interceptors.response.use(
      (response) => response,
      (...args) => this.responseHandler(...args)
    );
  }

  /**
   * Set up the handler that sets the current user after a successful fetch
   */
  setupUserStorageHandler() {
    this.userStorageHandler = (response) => {
      const user = response.data.data;
      return this.userStorage.set(user);
    };
  }

  /**
   * Set up the handler that redirects the user after a successful login
   */
  setupLoginRedirectHandler() {
    this.loginRedirectHandler = (item) => {
      this.router.replace(this.redirect);
      return item;
    };
  }

  /**
   * Set up the handler that redirects the user when logging out
   */
  setupLogoutRedirectHandler() {
    this.logoutRedirectHandler = () => {
      window.location.href = '/login';
    };
  }

  /**
   * See if a url is excluded from the refresh flow
   * @param url
   * @returns {boolean}
   */
  isExcludedRoute(url) {
    for (const excludedRoute of AuthService.excludedRoutes) {
      if (url.indexOf(excludedRoute) !== -1) {
        return true;
      }
    }
    return false;
  }

  /**
   * Set up the handler for resending a failed request after successful refresh
   */
  setupResendHandler() {
    this.resendHandler = async (requestConfig) => {
      // Do not re-use old token
      delete requestConfig.headers.Authorization;
      return this.HTTP.request(requestConfig);
    };
  }

  /**
   * Sets up the handler for when a refresh fails
   */
  setupRefreshFailHandler() {
    this.refreshFailHandler = async (error) => {
      const elseHandler = async () => {
        if (this.hasUserEverLoggedIn) {
          await this.logoutHandler();
        }
        throw error;
      };

      if (this.hasUserEverLoggedIn && this.interactiveRefreshHandler !== null) {
        return this.interactiveRefreshHandler()
          .then(() => this.resendHandler(error.config))
          .catch(elseHandler);
      }
      return elseHandler();
    };
  }
}
