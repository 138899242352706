// This import is required for typing
import Vue from 'vue';

export function useInertia(): InertiaPlugin {
  return Vue.prototype.$inertia;
}

export default function install(Vue: any) {
  let seed: number = 1;
  let lastName: string = 'A';

  Vue.prototype.$inertia = {
    getSeed: () => seed,
    setSeed: (newSeed: number | null) => {
      seed = newSeed ?? 1;
    },
    getLastName: () => lastName,
    setLastName: (newLastName: string | null) => {
      lastName = newLastName ?? 'A';
    },
  };
}

export interface InertiaPlugin {
  getSeed: () => number;
  setSeed: (newSeed: number | null) => void;
  getLastName: () => string;
  setLastName: (newLastName: string | null) => void;
}

declare module 'vue/types/vue' {
  interface Vue {
    $inertia: InertiaPlugin;
  }
}
