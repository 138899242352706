import {HTTP} from '@/common/api/http';
import {API_BASE_URL} from '@/common/api/base';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';
import {RawLoginTokenResponse} from '@/auth/types/RawLoginTokenResponse';

const BASE_URL = API_BASE_URL + '/auth';

/**
 * Send a login request
 *
 * @param {string} email
 * @param {string} password
 * @param {boolean} rememberMe
 * @returns {Promise<RawLoginTokenResponse>}
 */
export const login = async (
  email: string,
  password: string,
  {rememberMe = false} = {}
): Promise<RawLoginTokenResponse> => {
  return HTTP.post(`${BASE_URL}/login`, {
    email,
    password,
    rememberMe,
  });
};

/**
 * Request a new authentication token
 *
 * @returns {Promise<any>}
 */
export const refresh = async () => {
  return HTTP.post(`${BASE_URL}/refresh`, null);
};

/**
 * Logs the user out
 *
 * @returns {IStembleAxiosPromise}
 */
export const logout = async (): IStembleAxiosPromise => {
  return HTTP.post(`${BASE_URL}/logout`);
};
