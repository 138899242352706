import {getInstance} from '@/auth/plugins/authServicePlugin';
import {DASHBOARD, INERTIA_LEGACY_TASK, VERIFY_EMAIL} from '@/router/route-names';

/**
 * Returns true if the route requires verification and the user is not verified
 * @param to
 * @param user
 * @returns {boolean}
 */
const requiresVerification = (to, user) => {
  return to.matched.some((record) => record.meta.requiresVerified) && !user.hasVerifiedEmail;
};

/**
 * Returns true if the route requires that a user not be verified but the user is verified
 * @param to
 * @param user
 * @returns {boolean | *}
 */
const isAlreadyVerified = (to, user) => {
  return to.matched.some((record) => record.meta.requiresNotVerified) && user.hasVerifiedEmail;
};

/**
 * Function to check if a given route requires an authenticated user
 * @param to
 * @return {*}
 */
export const requiresAuthenticatedUser = (to) => {
  return to.matched.some((record) => {
    return (
      record.meta.requiresAuth ||
      record.meta.requiresVerified ||
      record.meta.gateCheck ||
      record.meta.authorize ||
      record.meta.requiresNotVerified
    );
  });
};

/**
 * Returns true if the route requires that a user be anonymous
 * @param to
 * @returns {boolean}
 */
export const requiresAnonymousUser = (to) => {
  return to.matched.some((record) => record.meta.requiresNotAuth || record.meta.requiresAnonymous);
};

/**
 * Returns true if all of the gate check functions return true
 * @param to
 * @param user
 * @returns {boolean}
 */
const checkGateChecks = (to, user) => {
  return to.matched.reduce((acc, record) => {
    if (!acc) {
      return acc;
    }

    let authorizer = null;
    if (record.meta.authorize) {
      authorizer = record.meta.authorize;
    } else if (record.meta.gateCheck) {
      authorizer = record.meta.gateCheck;
    }

    if (authorizer !== null) {
      acc = acc && authorizer({user, to, params: to.params});
    }

    return acc;
  }, true);
};

/**
 * Redirects to the verification
 * @param to
 * @param next
 */
const redirectToVerify = (to, next) => {
  next({
    name: VERIFY_EMAIL,
    query: {redirect: to.fullPath},
    replace: true,
  });
};

/**
 * Redirects to the dashboard
 * @param next
 */
const redirectToDashboard = (next) => {
  next({
    name: DASHBOARD,
    replace: true,
  });
};

/**
 * Goes back
 * @param from
 * @param next
 */
const goBack = (from, next) => {
  next({
    ...from,
    replace: true,
  });
};

const errorIsUnauthenticatedOrLogout = (err) => {
  return err && ((err.response && err.response.status === 401) || err.isLogout);
};

/**
 * Auth middleware to redirect users if they are not authenticated or authorized for a route
 * @param to
 * @param from
 * @param next
 */
export const authMiddleware = (to, from, next) => {
  if (to.name === INERTIA_LEGACY_TASK) {
    next();
    return;
  }

  const authService = getInstance();
  const wasUserResolved = authService.isUserResolved;
  const userPromise = authService.fetchCurrentUser();

  // If a user is loading, immediately resolve so that the user isn't stuck
  // waiting for the User API request to finish with no feedback
  // (loading feedback should be shown until the request is resolved)
  //
  // Otherwise, check for any authorization checks and proceed appropriately.
  if (!wasUserResolved) {
    next();
  }

  if (requiresAuthenticatedUser(to)) {
    userPromise
      .then((user) => {
        // At this point, the user is authenticated, but there may be further checks
        if (requiresVerification(to, user)) {
          redirectToVerify(to, next);
        } else if (isAlreadyVerified(to, user)) {
          redirectToDashboard(next);
        } else if (!checkGateChecks(to, user)) {
          if (wasUserResolved) {
            next(false);
          } else {
            goBack(from, next);
          }
        } else if (wasUserResolved) {
          next();
        }
      })
      .catch((err) => {
        if (errorIsUnauthenticatedOrLogout(err)) {
          window.location.href = '/login';
        } else {
          throw err;
        }
      });
  } else if (requiresAnonymousUser(to)) {
    // A select few routes require an anonymous user (e.g. login page), so if
    // the user promise resolves, we know they're not anonymous, so redirect.
    userPromise
      .then(() => {
        if (wasUserResolved) {
          next(false);
        } else {
          redirectToDashboard(next);
        }
      })
      .catch((err) => {
        if (!errorIsUnauthenticatedOrLogout(err)) {
          throw err;
        }
        if (wasUserResolved) {
          next();
        }
      });
  } else {
    userPromise.catch((err) => {
      if (!errorIsUnauthenticatedOrLogout(err)) {
        throw err;
      }
    });

    if (wasUserResolved) {
      next();
    }
  }
};
