import User from '@/users/models/User';
import {ActionFactory} from '@/orm/api/actions';
import {getUser, getUsers, patchUser} from '@/users/api/users';
import {sendInvitationEmail as apiSendInvitationEmail} from '@/users/api/user-invitations';

const factory = new ActionFactory(() => User);

export const fetch = factory.insertAction(getUsers);
export const get = factory.insertAction(getUser);
export const patch = factory.insertAction(patchUser);
export const sendInvitationEmail = factory.insertAction(apiSendInvitationEmail);
