import Analytics, {AnalyticsInstance} from 'analytics';
import User from '@/users/models/User';
import {sentryPlugin} from '@/telemetry/plugins/vue-analytics/sentry-plugin';
import {getEnvironment} from '@/environment';
import {IVueAnalyticsPluginOptions} from '@/telemetry/plugins/vue-analytics/IVueAnalyticsPluginOptions';

/*
 * Augment Vue type
 */
declare module 'vue/types/vue' {
  interface Vue {
    $analytics: AnalyticsInstance;
  }
}

/**
 * Installs the analytics plugin
 * @param {Vue} Vue
 * @param {IVueAnalyticsPluginOptions} options
 */
export default function install(
  Vue: Vue,
  {analyticsOptions, router, auth}: IVueAnalyticsPluginOptions
) {
  const plugins = analyticsOptions?.plugins ?? [];

  const env = getEnvironment();

  if (env.sentryDsn) {
    plugins.push(sentryPlugin());
  }

  const instance = Analytics({
    app: 'stemble',
    ...analyticsOptions,
    plugins,
  });

  // @ts-ignore
  Vue.prototype.$analytics = instance;

  if (auth) {
    auth.postUserLoad((user: User) => {
      // @ts-ignore FIXME: remove when model types are merged
      instance.identify(user.id.toString(), {email: user.email});
    });

    auth.postLogout(() => {
      instance.reset();
    });
  }
}
