export enum LoadingFlag {
  DashboardAssignments,
  DashboardGrades,
  CurrentUserCourses,
  CurrentUserPaymentRequests,
  DashboardEvents,
  RedirectingToCheckout,
  PayingWithAccessCode,
  UserProfileUser,
  UserProfileCourses,
  CurrentUser,
  LoggingIn,
  RegisterUser,
  ResendInvitation,
  VerifyUser,
  ResendVerifyEmail,
  SendVerifyEmail,
  PasswordResetRequest,
  DeletingEvent,
  SavingEvent,
  AccountSetting,
  ChangingPassword,
  ProfileSetting,
  LoadingInstitutions,
  LoadingAssignmentGrade,
  CoursePageAssignments,
  CoursePageAssignmentGrades,
  CourseWrapperCourse,
  ConversationWrapperConversation,
  RosterManagementSaveRole,
  RosterManagementSaveBulkRole,
  RosterManagementAddRoles,
  RosterManagementAddUsers,
  RosterManagementDeleteUsers,
  RosterManagementLoadParticipants,
  RosterManagementLoadRoles,
  RosterManagementLoadingUsers,
  Extensions,
  AddExtensions,
  AssignmentForExtensions,
  CourseRoleForExtensions,
  UsersByLocation,
  UsersDayOfWeek,
  UsersDeviceBreakdown,
  UsersBySessionDate,
  AssignmentUser,
  AssignmentAssignmentGrade,
  AssignmentWrapperAssignment,
  AssignmentCourseLikeAssignment,
  AssignmentTaskResponses,
  AssignmentTaskGrades,
  AssignmentExtensions,
  AssignmentGetAllGrades,
  AssignmentTaskState,
  AssignmentSubmittingResponse,
  AssignmentStoringResponse,
  AssignmentRegradingResponse,
  DeleteAssignment,
  AssignmentHasResponses,
  DuplicatingAssignments,
  LoadingAllTasks,
  SavingAssignment,
  CourseGradesLoadingParticipants,
  CourseGradesLoadingAssignmentGrades,
  CourseGradesLoadingAssignments,
  AssignmentGradesLoadingParticipants,
  AssignmentGradesLoadingGrades,
  StudentGradesLoadingAssignments,
  InviteUser,
  CreateCourseLoadingInstitutions,
  CreateCourseCreatingCourse,
  CourseEditorCreateCourse,
  CourseEditorPatchingCourse,
  PerformanceAnalyticsLoadingAssignments,
  PerformanceAnalyticsLoadingUsers,
  PerformanceAnalyticsLoadingResponseChartData,
  PerformanceAnalyticsLoadingErrorChartData,
  Testing,
  LoadingAllTaskTopics,
  LoadingAllTaskTypes,
  TaskEditorPatchTask,
  FileUploadUpload,
  FileUploadDownload,
  FileUploadDelete,
  TaskStateCsvExport,
  ManualGradeSubmit,
  InstitutionEditorCreateInstitution,
  InstitutionEditorPatchInstitution,
  MarkingViewLoadStudents,
  Conversation,
}
