import Vue from 'vue';
import Router from 'vue-router';

import {useAuthService} from '@/auth/services/authService';
import {authMiddleware} from '@/router/auth';
import {
  CHANGE_PASSWORD,
  COURSE_HOME,
  DASHBOARD,
  INERTIA_LEGACY_TASK,
  INVITE_A_USER,
  LOGIN,
  NOT_FOUND,
  PROFILE,
  SEARCH_USER,
  SUPER_ADMIN_ROOT,
  TASK_EDITOR,
} from '@/router/route-names';

// Authorizers
import {isSuperUser} from '@/router/authorizers';

import DummyView from '@/common/components/DummyView.vue';
import {courseNameRenderer} from '@/router/breadcrumbs';

const DashboardIndex = () => import('@/dashboard/views/DashboardIndex');
const IndexPages = () => import('@/AppIndex');

// Faculty & Students Pages
const CourseWrapper = () => import('@/courses/views/CourseWrapper.vue');
// General Pages
const Error404 = () => import('@/errors/views/NotFound');
const InviteUser = () => import('@/super-admin/views/InviteUser');
const SearchUser = () => import('@/super-admin/views/SearchUser');

const LegacyTask = () => import('@/tasks/components/LegacyTask.vue');

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      redirect() {
        const authService = useAuthService();
        return {name: authService.isUserAnonymous ? LOGIN : DASHBOARD};
      },
    },
    {
      path: '/',
      component: DashboardIndex,
      meta: {
        requiresVerified: true,
      },
      children: [
        {
          path: '/courses/:courseId',
          component: CourseWrapper,
          meta: {
            breadcrumbs: {
              name: COURSE_HOME,
              text: courseNameRenderer,
            },
          },
          children: [
            {
              path: '',
              name: COURSE_HOME,
              beforeEnter(to) {
                window.location.replace('/courses/' + to.params.courseId);
              },
            },
          ],
        },
        {
          name: PROFILE,
          path: '/profile',
          beforeEnter() {
            // Put the full page URL including the protocol http(s) below
            window.location.replace('/profile');
          },
        },
        {
          path: '/password/edit',
          name: CHANGE_PASSWORD,
          beforeEnter() {
            // Put the full page URL including the protocol http(s) below
            window.location.replace('/password/edit');
          },
        },
        {
          name: SUPER_ADMIN_ROOT,
          path: '/admin',
          meta: {
            authorize: isSuperUser(),
          },
          component: DummyView,
          children: [
            {
              name: INVITE_A_USER,
              path: 'invite-user',
              component: InviteUser,
            },
            {
              name: SEARCH_USER,
              path: 'user-search',
              component: SearchUser,
            },
          ],
        },
        {
          path: '/tasks/:taskId',
          component: DummyView,
          meta: {
            breadcrumbs: {
              name: TASK_EDITOR,
            },
            authorize({user}) {
              return user.isSuperUser();
            },
          },
          children: [
            {
              name: TASK_EDITOR,
              path: 'metadata',
              component: () => import('@/tasks/views/TaskEditor.vue'),
            },
          ],
        },
      ],
    },
    {
      path: '/',
      component: IndexPages,
      children: [
        {
          name: NOT_FOUND,
          path: '/404',
          component: Error404,
        },
      ],
    },
    {
      name: INERTIA_LEGACY_TASK,
      path: '/inertia/task',
      component: LegacyTask,
    },

    {
      path: '*',
      redirect: '/404',
    },
  ],
});
router.beforeEach(authMiddleware);

export default router;
